import { Injectable, EventEmitter } from '@angular/core';

import { UserRole } from '../../interfaces/userRole';
import { RoleService } from 'src/app/services/auth/role.service';

import { MsalService } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';

import { Client } from '@microsoft/microsoft-graph-client';
import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    userInfo: Array<UserRole> = [];
    permissionType: string;

    public dynamicRoleEvent: EventEmitter<string>;

    public graphClient?: Client;

    constructor(
        private msalService: MsalService,
        private role: RoleService,
    ) {
        this.dynamicRoleEvent = new EventEmitter();
    }

    public setUserInfo(u: any) {
        this.userInfo = u;
    }

    public getUserInfo(): Array<UserRole> {
        return this.userInfo;
    }

    public getUserRoleName(): string {
        const { userInfo: u } = this;
        let roleName = '';
        const isDP_DPO = u.some(e => {
            if (e.roleCode === 'GR') {
                return e.grouplist.some(u => u.groupCode === 'GLR-GDP');
            }
        });
        roleName = isDP_DPO ? 'DP' : 'GR';
        const isDPO = u.some(e => {
            if (e.roleCode === 'GR') {
                return e.grouplist.some(u => u.groupCode === 'GLR-DPO');
            }
        });
        if (isDPO) { roleName = 'DPO'; }
        const isFYI = u.some(e => {
            if(e.roleCode === 'GR'){
                return e.grouplist.some(u => u.groupCode === 'GLR-OTHER')
            }
        });
        if (isFYI) { roleName = 'FYI'; }
        const isGLR_GCC = u.some(e => { // Geographic Compliance & Corporate (general)
            if (e.roleCode === 'GR') {
                return e.grouplist.some(u => u.groupCode === 'GLR-GCC');
            }
        });
        if (isGLR_GCC) { roleName = 'GLR-GCC'; }
        const isPM = u.some(e => e.roleCode === 'PM');
        if (isPM) {
            const onlyMD = u[0].grouplist.length === 1 && u[0].grouplist.some(e => e.groupCode === 'PM-MD');
            if (onlyMD) {
                roleName = 'MD';
            } else {
                roleName = 'PM';
            }
        }
        const isLoc = u.some(e => e.roleCode === 'LOR' && (e.roleType !== 'ELR' && e.roleType !== 'WC'));
        if (isLoc) { roleName = 'LOR'; }
        const isELR = u.some(e => e.roleCode === 'LOR' && e.roleType === 'ELR');
        if (isELR) { roleName = 'ELR'; }
        const isERB = u.some(e => e.roleCode === 'LOR' && e.roleType === 'WC');
        if (isERB) { roleName = 'WC'; }
        const isLAC = u.some(e => e.roleCode === 'LAC');
        if (isLAC) { roleName = 'LAC'; }
        const isCFl = u.some(e => e.roleCode === 'CFL');
        if (isCFl) {
            const isCFLMD = u[0].grouplist.some(e => e.groupCode === 'PM-MD');
            if (isCFLMD) {
                roleName = 'CFL-MD';
            } else {
                roleName = 'CFL';
            }
        }
        const isSys = u.some(e => e.roleCode === 'Admin');
        if (isSys) { roleName = 'Admin'; }
        return roleName;
    }

    /**
     * Get route jump path By caseStatusCd & statusBeforClosed
     * @param card object
     * @returns url string
     * */
    public getRouteUrlByStatus(card) {
        if (this.role.caseInfo.isELROrWC) {
            return '/portal/myGLR/global/local-review';
        }
        const key = (card.caseStatusCd === 'MC' || card.caseStatusCd === 'REMC') ? 'statusBeforClosed' : 'caseStatusCd';
        let url = '/portal/myGLR/question';
        if (card[key] === 'GLR' || card[key] === 'REGLR') {
            url = '/portal/myGLR/global';
            if (this.getUserRoleName() === 'LOR' || this.getUserRoleName() === 'RELOR') { // current user is local review
                url = '/portal/myGLR/global/local-review';
            }
        } else if (card[key] === 'GLRLOR' || card[key] === 'REGLRLOR') {
            if (this.getUserRoleName() === 'LOR' || this.getUserRoleName() === 'RELOR') { // current user is local review
                url = '/portal/myGLR/global/local-review';
            } else { // any other users enter into DP assessment page
                url = '/portal/myGLR/global';
            }
        } else if (card[key] === 'LOR' || card[key] === 'RELOR' || card[key] === 'ELR' || card[key] === 'WC') {
            url = '/portal/myGLR/global/local-review';
        } else if (card[key] === 'FINAL' || card[key] === 'REFINAL') {
            url = '/portal/myGLR/global/final-review';
        } else if (card[key] === 'CM' || card[key] === 'RECM') {
            url = '/portal/myGLR/global/final-review';
        }
        return url;
    }

    public async getUserByGraphAPI() {
        const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(
            this.msalService.instance as PublicClientApplication,
            {
                account: this.msalService.instance.getActiveAccount(),
                scopes: ['user.read'],
                interactionType: InteractionType.Silent,
            }
        );
        // Initialize the Graph client
        this.graphClient = Client.initWithMiddleware({
            authProvider: authProvider,
        });
        // Get the user from Graph (GET /me)
        const data = await this.graphClient.api('/me/photo/$value').get();
        return data;
    }
}
