export enum CaseStatusTxt {
    SR = 'Case Creation', // Start Review
    IR = 'Initial Review', // Initial Review
    GLR = 'Global Legal Review', // Global Legal Review
    LOR = 'Local Review', // Local Review
    CM = 'Completed', // Completed
    LR = 'Legal Review', // Legal Review
    CC = 'Completed' // Auto Closed
}

export enum SectionEnum {
    CASEINFO = 'Case Information',
    PRE_ASSESS = 'Pre-Assessment',
    D_QNR = 'Detailed Questionnaire',
    LAS = 'Legal Assessment',
    GAS = 'General Assessment',
    GLA = 'Global Assessment',
    LLA = 'Local Assessment',
    LAM = 'Manage Case',//'Case Assignment'
    ACTION = 'Action(s)',
    DOC = 'Uploaded Document(s)',
    SUMARRY = 'Case Summary',
    FLR = 'Final Assessment',
    DIS = 'Discussions',
    // ----- global review teams -------
    GDP = 'Global Data Privacy',
    GEL = 'Global Employment Law',
    ED = 'E-Discovery',
    TC = 'Trade Compliance',
    IPT = 'IP Team',
    CIL = 'Corporate Investigations/Litigation'
}

export enum RoleCode {
    LAC = 'LAC', // 'Legal Area Coordinator'
    GR = 'GR', // 'Global Review'
    PM = 'PM', // 'Project Team'
    LOR = 'LOR', // 'Local Review'
    CFL = 'CFL',
    Admin = 'Admin', // 'System Administrator'
    ELR = 'ELR',
    WC = 'WC',
}

export enum GroupCode {
    'Admin' = 'Admin', // System Administrator
    'LAC-CIO' = 'LAC-CIO', // Legal Area Coordinator -CIO
    'LAC-IS' = 'LAC-IS', // Legal Area Coordinator -IS
    'LAC-ISA' = 'LAC-ISA', // Legal Area Coordinator -ISA
    'LAC-HR' = 'LAC-HR', // Legal Area Coordinator -HR
    'LAC-Marketing' = 'LAC-Marketing', // Legal Area Coordinator -Marketing
    'LAC-Analytics' = 'LAC-Analytics', // Legal Area Coordinator -Analytics
    'LAC-Avanade' = 'LAC-Avanade', // Legal Area Coordinator -LAC-Avanade
    'LAC-Other' = 'LAC-Other', // LAC	Legal Area Coordinator -LAC-Other
    'LAC-GS' = 'LAC-GS', // LAC	Legal Area Coordinator -Geographic Services
    'GLR-GDP' = 'GLR-GDP', // Global Review -Global Data Privacy
    'GLR-GEL' = 'GLR-GEL', // Global Review -Global Employment Law
    'GLR-ED' = 'GLR-ED', // Global Review -E-Discovery
    'GLR-TC' = 'GLR-TC', // Global Review -Trade Compliance
    'GLR-IPT' = 'GLR-IPT', // Global Review -IP Team
    'GLR-CI' = 'GLR-CI', // Global Review -Corporate Investigations/Litigation
    'GLR-DPO' = 'GLR-DPO', // Global Review -DPO
    'GLR-GCC' = 'GLR-GCC', // Global Review -Geographic Compliance & Corporate (general)
    'GLR-Other' = 'GLR-Other', // Global Review -Other
    'PM-LR' = 'PM-LR', // Project Team -PM
    'PM-SR' = 'PM-LR', // Project Team -SR
    'PM-MD' = 'PM-LR', // Project Team -MD
    'Local Review' = 'Local Review', // Local Review
    'GLR-OTHER' = 'GLR-OTHER' // GLR OTHERS FYI
}

export enum ActionStage {
    Draft = 'Draft',
    Pending = 'Pending',
    Completed = 'Actioned',
    Closed = 'Closed'
}

export enum AssignTo {
    GR = 'Global Review',
    LAC = 'Legal Area Coordinator',
    LOR = 'Local Review',
    LR = 'Lead Requestor'
}

export enum CaseStatus {
    DR = 1, //Draft
    PR = 2, //Pending LAC Review
    CC = 3, //Case Closed
    CM = 4, //Completed
    SR = 5, //Case Creation
    IR = 6, //Initial Review
    LOR = 7, //Local Review
    LR = 8, //Legal Review
    GLR = 9, //Global Review
    GLRLOR = 10, //Global Review/Local Review
    FINAL = 11, //Final Review
    MC = 12, //Manually Closed
}

export enum DataPrivacyTitle {
    OtherLocalLegal = 'Other Local Legal & Regulatory Requirements',
    Lawful = 'Lawful And Fair Processing & Purpose Limitation',
    Transparency = 'Transparency',
    Security = 'Security, Confidentiality And Integrity',
    StorageThirdParty = 'Storage Location, Third Party Access & International Transfer Of Data To Third Parties',
    Individual = 'Individual Rights Requests',
    DataMinimization = 'Data Minimization, Proportionality And Necessity',
    StorageAccess = 'Storage Location, Access & International Transfer Of Data Within Accenture',
    Retention = 'Retention, Deletion & Storage Limitation',
    Additional = 'Additional Privacy By Design Principles And Protection of Personal Data',
    DeployInd = 'DeployInd',
    SensitiveData = 'Sensitive Data'
}