import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of } from 'rxjs';

import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class PreLoadLazyModuleServie implements PreloadingStrategy {
    preload(route: Route, load: () => Observable<any>): Observable<any> {
        if (route.data && route.data.preload) {
            if (environment.preload) {
                // setTimeout(() => {
                    return load();
                // }, 2000);
            } else {
                return of(null);
            }
        } else {
            return of(null);
        }
    }
}
