import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

import { HomeComponent } from './views/modules/home/home.component';
import { ContactComponent } from './views/modules/contact/contact.component';
import { DeniedComponent } from './views/component/denied/denied.component';
import { ViewLayoutComponent } from './views/view-layout/view-layout.component';

import { PreLoadLazyModuleServie } from './services/route/preload.service';

const routes: Routes = [
    {
        path: 'portal',
        component: ViewLayoutComponent,
        canActivate: [
            MsalGuard
        ],
        children: [
            {
                path: '',
                redirectTo: 'home',
                pathMatch: 'full'
            },
            {
                path: 'home',
                component: HomeComponent
            },
            {
                path: 'resource',
                component: ContactComponent
            },
            {
                path: 'contantUs',
                component: ContactComponent
            },
            {
                path: 'myGLR',
                loadChildren: () => import('./views/modules/myGLR/my-glr.module').then(m => m.MyGLRModule),
                data: { preload: true }
            }
        ]
    },
    {
        path: 'access-denied',
        component: DeniedComponent
    },
    {
        path: '',
        redirectTo: 'portal',
        pathMatch: 'full'
    },
    {
        path: '**',
        redirectTo: 'portal'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreLoadLazyModuleServie })],
    exports: [RouterModule]
})
export class AppRoutingModule {}
