import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AuthService } from 'src/app/services/auth/auth.service';
// import { PeoplePickerService } from 'src/app/services/api/peoplepicker.api';
import { Router, NavigationEnd } from '@angular/router';
import { MessageService } from 'primeng/api';
import { MyglrService } from 'src/app/services/api/myglr.service';
import { LoadingService } from 'src/app/services/loading.service';
import { environment } from '../../../../environments/environment';
import { RoleService } from '../../../../app/services/auth/role.service';

import { nzBadgeAccessbility, ToastAccessbility } from 'src/app/common/util/dom';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {

    backToUrl() {
        throw new Error('Method not implemented.');
    }

    @ViewChild('bellPopover') bellPopover: ElementRef;

    user: any;
    userPictureUrl: any;
    routeUrl: any;
    apiError: any;

    showLogOut: boolean;

    headerList = [
        { name: 'Home', active: true, url: 'portal/home', id: 'HomeTab' },
        { name: 'My GLR', active: false, url: 'portal/myGLR', id: 'MyGLRTab' },
        { name: 'Resources', active: false, url: 'portal/resource', id: 'ResourcesTab' }
    ];

    discussionUnread = 0;
    bellMsgList = [];

    popoverVisible = true;
    initPopoverVisible = false;
    openManagePage = false;
    isAdmin: boolean;

    routerDestr: any;

    constructor(
        private authService: MsalService,
        private auth: AuthService,
        private router: Router,
        private MsalService: MsalService,
        private loading: LoadingService,
        // private peoplePickerService: PeoplePickerService,
        private messageService: MessageService,
        private myGlrService: MyglrService,
        private roleService: RoleService
    ) {
        // this.user = this.MsalService.getAccount();
        this.showLogOut = !environment.isProduction;
        this.user = this.MsalService.instance.getAllAccounts()[0];
        this.routerDestr = this.router.events.subscribe(data => {
            if (data instanceof NavigationEnd) {
                this.routeUrl = data.url.substr(1);
                if (this.routeUrl !== 'portal/myGLR/manageuser' && this.routeUrl !== '') {
                    this.openManagePage = false;
                    this.headerList.forEach(element => {
                        element.active = this.routeUrl.includes(element.url);
                    });
                } else if (this.routeUrl === 'portal/myGLR/manageuser') {
                    this.openManagePage = true;
                    this.headerList.forEach(element => {
                        element.active = false;
                    });
                }
            }
        });
    }

    ngOnDestroy(): void {
        this.routerDestr.unsubscribe();
    }

    ngOnInit() {
        this.getGraphPhoto();
        // this.headerPeoplePicture();
        this.getHeaderBellMsg();
    }

    getHeaderBellMsg() {
        return this.myGlrService.getHeaderBellMsg().then(res => {
            if (res && res.resultFlag) {
                this.discussionUnread = res.response.userNotificationCount;
                this.bellMsgList = res.response.userNotificationList;
                this.roleService.isAdminUser = res.response.isAdmin;
                this.isAdmin = this.roleService.isAdminUser;
            } else {
                this.messageService.add({ life: 8000, severity: 'error', detail: 'Get getHeaderBellMsg failed' });
                this.doToastMessageAccessbility();
            }
            this.accessbility();
        }).catch(() => {
            console.error('getHeaderBellMsg api has error!!!!');
        }).finally(() => {
            nzBadgeAccessbility();
        });

    }

    // backToUrl() {
    //     const { routeUrl } = this;
    //     if (routeUrl !== '' && routeUrl !== 'portal/myGLR/manageuser') {
    //         this.headerList.forEach(element => {
    //             element.active = this.routeUrl.includes(element.url);
    //         });
    //     }
    // }

    changeMenu(item) {
        this.headerList.forEach(head => {
            if (head.name === item) {
                head.active = true;
            } else {
                head.active = false;
            }
        });
        switch (item) {
            case 'Home':
                this.router.navigate(['/portal/home']);
                break;
            case 'My GLR':
                if (!environment.preload) { // handle performance when network is poolnpm
                    this.loading.commitLoading(true);
                }
                this.router.navigate(['/portal/myGLR/']);
                break;
        }
    }

    ngAfterViewInit(): void {

    }

    accessbility() {
        setTimeout(() => {
            const container = document.querySelectorAll('.ant-scroll-number');
            if (container) {
                container.forEach((con, j) => {
                    con.setAttribute('tabindex', '0');
                });
            }
        }, 3000);
    }

    logout() {
        this.authService.logout();
    }

    clickImg() {
        window.open('https://people.accenture.com/People/user/' + this.user.idTokenClaims['enterpriseID']);
    }

    // headerPeoplePicture() {
    //     // const eid = this.user.idToken.enterpriseID;
    //     const eid = this.user.idTokenClaims['enterpriseID'];
    //     this.peoplePickerService.getPeoplePicture(eid).then(res => {
    //         if (res !== null && res !== '') {
    //             this.apiError = true;
    //             this.userPictureUrl = 'data:image/jpg;base64,' + res.response;
    //         }
    //     }).catch(() => {
    //         this.apiError = false;
    //         console.error('GetPeoplePicture api has error!!!!');
    //     });
    // }

    getGraphPhoto() {
        this.auth
            .getUserByGraphAPI()
            .then((res) => {
                this.blobToDataURI(res, (data: any) => {
                    this.apiError = true;
                    this.userPictureUrl = data;
                });
            })
            .catch(err => {
                this.apiError = false;
                console.error('People profile: ' + err);
            })
    }

    blobToDataURI(blob:Blob, callback : any){
        const reader = new FileReader();
        reader.onload =  function(e) {
            callback(e.target?.result);
        };
        reader.readAsDataURL(blob);
    }

    bellClick() {
        this.messageService.add({ life: 8000, severity: 'error', detail: 'You do not have any notifications yet!' });
        this.doToastMessageAccessbility();
    }

    removeItem(item) {
        if (!item.caseNbr) { return; }
        const param = {
            caseUserNotificationId: item.caseUserNotificationId
        };
        this.myGlrService.deleteNotificationMsgItem(param).then(res => {
            if (res && res.resultFlag) {
                this.getHeaderBellMsg().then(() => {
                    if (this.bellMsgList.length === 0) {
                        this.popoverVisible = false;
                    }
                });
                // this.bellMsgList = this.bellMsgList.filter(i => i.caseId !== item.caseId);
            } else {
                this.messageService.add({ life: 8000, severity: 'error', detail: 'DeleteNotification failed' });
                this.doToastMessageAccessbility();
            }
        }).catch(() => {
            console.error('deleteNotification api has error!!!!');
        });
    }

    popoverVisibleChange(ev) {
        if (ev && !this.initPopoverVisible) { this.initPopoverVisible = true; }
    }

    goToCaseDetail(item) {
        const param = {
            caseId: item.caseId
        };
        const url = this.auth.getRouteUrlByStatus(item);
        this.bellPopover.nativeElement.click();
        if (window.location.href.includes('question') && url.includes('question')) {
            this.router.navigate(['/portal/myGLR/global'], { queryParams: { ...param, relyOn: true } }).finally(() => {
                setTimeout(() => {
                    this.router.navigate([url], { queryParams: param });
                }, 0);
            });
        } else if (window.location.href.includes('global') && url.includes('global')) {
            this.router.navigate(['/portal/myGLR/question'], { queryParams: param }).finally(() => {
                setTimeout(() => {
                    this.router.navigate([url], { queryParams: param });
                }, 0);
            });
        } else {
            this.router.navigate([url], { queryParams: param });
        }
    }

    doToastMessageAccessbility() {
        ToastAccessbility();
    }

    openManageUser() {
        this.router.navigate(['/portal/myGLR/manageuser']);
        this.openManagePage = true;
        this.headerList.forEach(head => {
            head.active = false;
        });
    }

}
