// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  isProduction: false,
  preload: true,
  hmr: false,
  darklyClientSideId: '6220a38dad7e181507dfe4d1',
  launchdarkly: 'sdk-5457e76f-ee2e-43a0-9a92-3193d494ea34',
  apiUrl: 'https://glrtesttemp.ciodev.accenture.com/restful',
  auth:
  {
      clientId: 'f4677e6a-00bf-49b9-9463-6ad8ccfca4c5',
      authority: 'https://login.microsoftonline.com/f3211d0e-125b-42c3-86db-322b19a65a22/',
      validateAuthority: true,
      redirectUri: 'https://glrtesttemp.ciodev.accenture.com',
      postLogoutRedirectUri: 'https://glrtesttemp.ciodev.accenture.com/logout',
      navigateToLoginRequestUrl: true
  }
};
