import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule } from '@angular/router';

// component
import { ViewLayoutComponent } from './view-layout/view-layout.component';
import { FooterComponent } from './view-layout/footer/footer.component';
import { HeaderComponent } from './view-layout/header/header.component';
import { LoadingComponent } from './view-layout/loading/loading.component';

import { HomeModule } from './modules/home/home.module';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { ContactComponent } from './modules/contact/contact.component';

@NgModule({
    declarations: [
        HeaderComponent,
        FooterComponent,
        LoadingComponent,
        ViewLayoutComponent,
        ContactComponent
    ],
    imports: [
        HomeModule,
        CommonModule,
        NzPopoverModule,
        NzBadgeModule,
        RouterModule
    ],
    exports: [],
    providers: []
})
export class ViewsModule {}
