import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { httpInterceptorProviders } from '../app/services/http';

// primeNg Module
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';

import { AppComponent } from './app.component';
import { DeniedComponent } from './views/component/denied/denied.component';
import { ViewsModule } from './views/views.module';

import { MsalModule, MsalInterceptor, MsalRedirectComponent, MsalGuard } from '@azure/msal-angular';
import { PublicClientApplication, InteractionType } from '@azure/msal-browser';

// import {
//     MSAL_CONFIG,
//     MSAL_CONFIG_ANGULAR,
//     MsalAngularConfiguration,
//     MsalInterceptor,
//     MsalModule,
//     MsalService
// } from '@azure/msal-angular';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
// import { Configuration } from 'msal';
import { environment } from './../environments/environment';
import { LoggerService } from './services/logger.service';

import { FormsModule } from '@angular/forms';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';

// tslint:disable-next-line: no-unused-expression
// !environment.production && require('../assets/mockApi');

registerLocaleData(en);

export const protectedResourceMap: [string, string[]][] = [
    ['https://graph.microsoft.com/v1.0/me', ['user.read']],
];

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

// function MSALConfigFactory(): Configuration {
//     return {
//         auth: {
//             clientId: environment.auth.clientId,
//             authority: environment.auth.authority,
//             validateAuthority: true,
//             redirectUri: environment.auth.redirectUri,
//             postLogoutRedirectUri: environment.auth.postLogoutRedirectUri,
//             navigateToLoginRequestUrl: true,
//         },
//         cache: {
//             cacheLocation: 'sessionStorage',
//             storeAuthStateInCookie: isIE, // set to true for IE 11
//         },
//     };
// }

// function MSALAngularConfigFactory(): MsalAngularConfiguration {
//     return {
//         consentScopes: [
//             'user.read',
//             'openid',
//             'profile',
//         ],
//         unprotectedResources: ['https://www.microsoft.com/en-us/'],
//         protectedResourceMap: [
//             ['https://graph.microsoft.com/v1.0/me', ['user.read']],
//         ],
//         extraQueryParameters: {},
//     };
// }

@NgModule({
    declarations: [
        AppComponent,
        DeniedComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        AppRoutingModule,
        ViewsModule,
        ToastModule,
        MsalModule.forRoot(new PublicClientApplication({
            auth: {
                clientId: environment.auth.clientId, // This is your client ID
                authority: environment.auth.authority, // This is your tenant ID
                redirectUri: environment.auth.redirectUri,// This is your redirect URI
                postLogoutRedirectUri: environment.auth.postLogoutRedirectUri,
                navigateToLoginRequestUrl: true
            },
            cache: {
                cacheLocation: 'sessionStorage',
                storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
            }
        }), {
            interactionType: InteractionType.Redirect,
            authRequest: {
                scopes: ['user.read']
            }
        }, {
            interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
            protectedResourceMap: new Map([
                ["https://graph.microsoft.com/v1.0/me", ['user.read']],
                [`${ environment.apiUrl }/*`, [`${ environment.auth.clientId }/.default`]]
            ])
        }),
        HttpClientModule
    ],
    exports: [
    ],
    providers: [
        MessageService,
        LoggerService,
        httpInterceptorProviders,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        MsalGuard,
        // {
        //     provide: MSAL_CONFIG,
        //     useFactory: MSALConfigFactory
        // },
        // {
        //     provide: MSAL_CONFIG_ANGULAR,
        //     useFactory: MSALAngularConfigFactory
        // },
        // MsalService,
        { provide: NZ_I18N, useValue: en_US }
    ],
    bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
