import { Injectable, Component, Input, TemplateRef } from '@angular/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';

@Injectable({
    providedIn: 'root'
})
export class ACModalService {

    modalObj;

    constructor(private modal: NzModalService) {}

    openModaltplTitle(tplTitle: any, tplContent: any, tplFooter: any, width?: number, className?: string): void {
        if (!this.modalObj) {
            this.modalObj = this.modal.create({
                nzTitle: tplTitle,
                nzContent: tplContent,
                nzFooter: tplFooter,
                nzMaskClosable: false,
                nzClosable: false,
                nzClassName: className || '',
                nzAutofocus: null,
                nzWidth: width || 840,
                nzComponentParams: {
                    value: 'Template Context'
                },
                nzOnOk: () => console.log(' ')
            });
        }
    }

    closeModal() {
        if (this.modalObj) {
            this.modalObj.destroy();
            this.modalObj = null;
        }
    }
}
