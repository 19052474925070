import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { finalize, tap } from 'rxjs/operators';
import { LoggerService } from '../logger.service';
import { MessageService } from 'primeng/api';
import { globalConstMessage } from 'src/app/common/constant/global.constant';


@Injectable()
export class LoggingInterceptor implements HttpInterceptor {
  constructor(private loggerService: LoggerService, private messageService: MessageService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const startTime = Date.now();
    let status: string;

    return next.handle(req).pipe(
      tap(
        event => {
          status = '';
          if (event instanceof HttpResponse) {
            status = 'succeeded';
          }
        },
        error => status = 'failed'
      ),
      finalize(() => {
        const elapsedTime = Date.now() - startTime;

        const message = `Request url:${req.url};
                         method:${req.method};
                         params:${req.urlWithParams};
                         status;${status};
                         elapsedTime:${elapsedTime}ms`;
        if (status === 'succeeded') {
          this.loggerService.log(message);
        } else if (status === 'failed') {
          this.loggerService.error(message);

          this.messageService.add({ life: 8000, severity: 'error', detail: this.getErrorMessage(req.urlWithParams) });
        }
      })
    );
  }

  getErrorMessage(params: string ): string {
    if (params.indexOf('Save') > -1){
      return globalConstMessage.failedSaveMessage;
    } else {
      return globalConstMessage.serverErrorMessage;
    }
  }
}
