// import { SharedModule } from '../../component/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { NzModalModule } from 'ng-zorro-antd/modal';

import { HomeComponent } from './home.component';
import { CreateCaseModalComponent } from '../myGLR/create-case-modal/create-case-modal.component';
// import { NzButtonModule } from 'ng-zorro-antd/button';


@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        NzModalModule,
        // SharedModule
    ],
    declarations: [
        HomeComponent,
        CreateCaseModalComponent
    ],
    exports: [
        HomeComponent,
        CreateCaseModalComponent,
        // NzButtonModule

    ]
})
export class HomeModule { }
