import { Component, OnInit,TemplateRef, AfterViewInit,ViewChild, HostListener, ElementRef } from '@angular/core';
import { LoadingService } from 'src/app/services/loading.service';
import { Router } from '@angular/router';
import { MyglrService } from 'src/app/services/api/myglr.service';
import { ACModalService } from 'src/app/services/utils/modal.service';
import { Console } from 'console';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit {

    isPopupVisible = false;

    togglePopup(){
        this.isPopupVisible = !this.isPopupVisible;
    }

    @HostListener('document:click',['$event'])
    onClick(event:MouseEvent){
        const clickedElement = event.target as HTMLElement;
        const popupButton = this.elementRef.nativeElement.querySelector('.help__button');

       if(popupButton && popupButton.contains(clickedElement)){
        return;
       } {
        this.isPopupVisible = false;
       }
    }

    @ViewChild('tml_body') tml_body: TemplateRef<any>;
    showCreate = false;
    showPlayIcon = true;
    noIntroduction = false;
    flipped: boolean[] = new Array();
    otherProcess = [{
        id: 0,
        title: "Data Protection Impact Assessment",
        content: "Follow the Data Protection Impact Assessment (DPIA) process ",
        link: "https://in.accenture.com/legal/dpiasupportsite/dpiafaqs/",
        linkedText: "here",
        img: "two"
    
    },
    {
        id: 1,
        title: "Data Privacy & Procurement",
        content: "For procurement agreements reviews, follow Hiperos (Coupa) onboarding process ",
        link: "https://rportal.hiperos.com/Dashboard/Home",
        linkedText: "here",
        img: "three"
    },
    {
    id: 2,
        title: "Local Legal & Data Privacy Support",
        content: "For any other local data privacy or local legal support (e.g. a local survey, a local legal advice), submit your request in the ",
        link: "https://ts.accenture.com/sites/LegalCore/GeographicLegalSupport/SitePages/Global%20Legal%20Request%20Tool%20Landing%20Page.aspx",
        linkedText: "Legal Request Tool",
        img: "one"
    },
    {
        id: 3,
        title: "Global Data Privacy Support",
        content: "For any other global data privacy support (e.g. surveys, database access, etc.), submit your request in ",
        link: "https://support.accenture.com/support_portal?id=acn_service_catalog_dp&sys_id=e37c1b97dba19b0081d000b5ca9619ab",
        linkedText: "Service Now",
        img: "four"
    }];
    // contentUrls = {
    //     support: 'https://in.accenture.com/legal/ethicscompliance/dataprivacy/globallegalreview'
    // };

    aaa = {
        a: 1,
        b: "222",
        c: true
    };

    constructor(
        private route: Router,
        private loading: LoadingService,
        private myGlr: MyglrService,
        private acModal: ACModalService,
        private myGlrService: MyglrService,
        private elementRef: ElementRef,
    ) {
        const { showCreate } = this.myGlr;
        if (showCreate === null) {
            this.myGlr.GetCreatePermission().then(res => {
                if (res && res.resultFlag) {
                    this.showCreate = this.myGlr.showCreate = res.response.createPermission;
                }
            });
        } else {
            this.showCreate = showCreate;
        }
    }

    ngOnInit(): void {
        this.myGlr.GetMyGLRShowMeAgain().then(res => {
            if (res) {
                this.noIntroduction = res.response;
            }
        });
    }

    ngAfterViewInit(): void {
        this.handleVideoEvent();
    }

    link(): void {
        if (this.noIntroduction) {
             this.route.navigateByUrl('/portal/myGLR/introduction');
        } else {
            this.acModal.openModaltplTitle(null, this.tml_body, null, 840, 'case-submit-popup');
            this.popAccessbility();
        //     // this.loading.commitLoading(true);
        //     // this.myGlr.getMyGlrCaseId().then(res => {
        //     //     this.route.navigate(['/portal/myGLR/question'], {
        //     //         queryParams: {
        //     //             caseId: res.response.caseId
        //     //         }
        //     //     });
        //     // }).finally(() => {
        //     //     this.loading.commitLoading(false);
        //     // });
         }
    }

    // goUrl(objName) {
    //     window.open(this.contentUrls[objName]);
    // }

    playVideo() {
        const video = document.querySelector('#GLR_Tutorial_VIDEO') as HTMLVideoElement;
        video.play();
    }

    handleVideoEvent() {
        const video = document.querySelector('#GLR_Tutorial_VIDEO');
        video.addEventListener('play', () => {
            this.showPlayIcon = false;
        });
        video.addEventListener('pause', () => {
            this.showPlayIcon = true;
        });
    }

    createModal(parse) {
        this.acModal.closeModal();
        if (parse === 'Yes') {
            this.loading.commitLoading(true);
            this.myGlrService.getMyGlrCaseId().then(res => {
                this.route.navigate(['/portal/myGLR/question'], {
                    queryParams: {
                        caseId: res.response.caseId
                    }
                });
            }).finally(() => {
                this.loading.commitLoading(false);
            });
        }
    }
    popAccessbility() {
        setTimeout(() => {
            const container = document.querySelectorAll('.cdk-visually-hidden');
            if (container) {
                container.forEach((con, j) => {
                    con.setAttribute('tabindex', '-1');
                });
            }
        }, 300);
    }

    flipTile(tileId) {
      this.flipped[tileId] = !this.flipped[tileId];
    }
}
