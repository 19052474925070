<section class="home-wrapper" id="home-wrapper">
  <div class="sec-one">
    <div class="sec-container">
      <div class="welcome">
        <h1>Welcome to GLR</h1>
        <p>The Global Legal Review (GLR) is a process to facilitate the legal review of global and local internal initiatives (e.g. analytics, a new technology or an enhancement of an existing technology, including migration tools) by coordinating recommendations from relevant legal teams and facilitating approvals from employee representative bodies and local government authorities.</p>
         <div id="create-btn" *ngIf="showCreate" class="create-case" (click)="link()">
          <i class="fa fa-angle-right"></i>
          <span>Create Case Now</span>
        </div>
      </div>
      <ng-template #tml_body>
        <div class="modal_container">
          <i id="icon_close" class="popup-icon-close" aria-hidden="true" tabindex="-1"
            (click)="createModal('cancel')"></i>
          <div class="modal_body" role="region" aria-label="content">
            You are about to create a new case.
            <span class="warning"></span>
            <div class="note"><span class="important">Important:</span>
            Do not create a new case if your initiative relates to an enhancement and/or change
            <br>to a solution that already has a Case in this tool. Work with the Lead Requestor of the existing 
            Case to reopen it. Learn more about how to reopen a Case <a href="https://in.accenture.com/legal/ethicscompliance/dataprivacy/globallegalreview/learningjourney/release-notes/"><font color=#b455aa><u>here</u></font></a> or contact
            <br><a href=mailto:“legalareacoordinators@accenture.com”><font color=#b455aa>legalareacoordinators&#64;accenture.com</font></a> for support.</div>
            <p>As the Lead Requestor of this new Case, you are responsible for providing all relevant information
            about the initiative, actively supporting Legal teams in their reviews and addressing all compliance
            <br>actions prior to deployment. 
            <br>Do you agree?
          </div>
          <div class="modal_footer fr">
            <button id="no-btn" class="no-btn mr16" role="region" aria-label="no" (click)="createModal('cancel')">No</button>
            <button id="yes-btn" class="ant-btn ok-btn" role="region" aria-label="yes" (click)="createModal('Yes')">Yes</button>
          </div>
        </div>
      </ng-template>
      <div class="glr-video">
        <i *ngIf="showPlayIcon" (click)="playVideo()" class="fa fa-play" aria-hidden="true" tabindex="-1"></i>
        <video poster="./assets/images/first_frame.png" id="GLR_Tutorial_VIDEO" preload="none" controls="controls">
          <source src="./assets/video/intro_glr.mp4" type="video/mp4">
        </video>
      </div>
      <div class="help__modal">
        <div id="needhelp-btn" class="help__button" (click)="togglePopup()">
          <div class="arrow-icon"></div>
          <span>Need help</span>
          <div class="help__popup" *ngIf="isPopupVisible">
            <div class="help__container">
              <a target="_blank" href="https://in.accenture.com/legal/ethicscompliance/dataprivacy/globallegalreview/about-glr/" rel="noopener">
                <div class="help__item">
                  <div class="help__icons">
                    <span class="help__icons--glass"></span>
                  </div>
                  <div id="needhelp-1" class="help__contents">
                    <p class="help__contents--title">GLR At A Glance</p>
                    <p class="help__contents--text">Get a quick snapshot about the GLR and what is expected from you at each GLR review stage.</p>
                  </div>
                </div>
              </a>
              <a target="_blank" href="https://in.accenture.com/legal/ethicscompliance/dataprivacy/globallegalreview/" rel="noopener">
                <div class="help__item">
                  <div class="help__icons">
                    <span class="help__icons--question"></span>
                  </div>
                  <div id="needhelp-2" class="help__contents">
                    <p class="help__contents--title">GLR Support Site</p>
                    <p class="help__contents--text">Visit Resources to access our contact information and learning materials, including job aids, video demonstrations, and FAQs.</p>
                  </div>
                </div>
              </a>
              <!-- * [Task 746441] Release 3.5: Removal of ChatBot for all GLR Services -->
              <!-- <a target="_blank" href="https://legalchatbot.accenture.com/" rel="noopener">
                <div id="needhelp-3" class="help__item">
                  <div class="help__icons">
                    <span class="help__icons--chat"></span>
                  </div>
                  <div class="help__contents">
                    <p class="help__contents--title">Data Privacy Chat Bot</p>
                    <p class="help__contents--text">Try our Data Privacy Chatbot for quick answers to your data privacy questions.</p>
                  </div>
                </div>
              </a> -->
            </div>
          </div>
        </div>
      </div>
      <div class="info">
        <div class="info-icon"></div>
        <b> For more information on GLR visit the <a rel="noopener noreferrer"
            style="color: #A125FC; text-decoration: none;" target="_blank"
            href="https://in.accenture.com/legal/ethicscompliance/dataprivacy/globallegalreview/ ">Resources section</a> or visit our 
          <a rel="noopener noreferrer" style="color: #A125FC; text-decoration: none;" target="_blank"
            href="https://in.accenture.com/legal/ethicscompliance/dataprivacy/globallegalreview/learningjourney/">GLR Learning material page.</a></b>
      </div>
    </div>
  </div>
  <div class="sec-two">
    <div class="container">
      <input type="radio" name="slider" id="item-1" checked>
      <input type="radio" name="slider" id="item-2">
      <input type="radio" name="slider" id="item-3">
      <div class="slides">
        <div class="controls">
          <div class="control" id="control-1">
            <label class="control__left ic_left" for="item-3"></label>
            <label class="control__right ic_right" for="item-2"></label>
          </div>
          <div class="control" id="control-2">
            <label class="control__left ic_left" for="item-1"></label>
            <label class="control__right ic_right" for="item-3"></label>
          </div>
          <div class="control" id="control-3">
            <label class="control__left ic_left" for="item-2"></label>
            <label class="control__right ic_right" for="item-1"></label>
          </div>
        </div>
        <div class="slide" id="info-1">
          <span class="title">
            <h2>What</h2>
            <hr>
          </span>
          <p class="text">The processing of <a rel="noopener noreferrer" target="_blank"
            href="https://ts.accenture.com/:w:/r/sites/policiesadmin/_layouts/15/Doc.aspx?sourcedoc=%7BA5184AAA-C6F7-4EAE-8129-CCC41537C92E%7D&file=Accenture%20Data%20Privacy%20Policy%2090%20Definitions%20-%20English%20(5.27.2020).docx&action=default&mobileredirect=true">personal data</a> in global or local internal initiatives (e.g. analytics, a new technology or an enhancement of an existing technology, including a migration) for Accenture/Avanade´s own purposes, and supported by its corporate functions or other internal teams (e.g. CIO, InfoSec, HR, Marketing, Operations, Enterprise Insights, CIO Analytics, Accenture Insights Anywhere)</p>
        </div>
        <div class="slide" id="info-2">
          <span class="title">
            <h2>Why</h2>
            <hr>
          </span>
          <p class="text">In line with Accenture <a rel="noopener noreferrer" target="_blank"
            href="https://policies.accenture.com/policy/0090">Policy</a> 90 /Avanade Policy 90 , you are responsible for conducting a privacy review which is part of the GLR. You also have the responsibility to ensure compliance with employment, trade, telecommunication, and other applicable laws and regulations: a review of those legal areas, where relevant, is also part of the GLR. The GLR enables you to identify risks, take remediation actions, and ensure compliance. Failure to comply with laws and regulations can have serious impacts including:<br>
            <span style="margin-left: 24px;">• Exposure to audit, fines, and legal actions<br></span>
            <span style="margin-left: 24px;">• Loss of trust and cooperation from applicable bodies (e.g. works
              councils, labor authorities) <br></span>
            <span style="margin-left: 24px;">• Delays in implementations, prevention from or undo rollout<br></span>
          </p>
        </div>
        <div class="slide" id="info-3">
          <span class="title">
            <h2>When</h2>
            <hr>
          </span>
          <p class="text">In line with Accenture <a rel="noopener noreferrer" target="_blank"
              href="https://policies.accenture.com/policy/0090">Policy</a> 90 /Avanade
              Policy 90 you should conclude the GLR prior to the start of a pilot or prior to deployment of an initiative that involves the processing of personal data.</p>
        </div>
      </div>
    </div>
  </div>
  <div class="sec-four">
    <div class="sec-container">
      <div class="other-process">
        <h2>Other Related Processes</h2>
        <div class="process-cont">
          <div [id]="'card-process-'+i" class="card" *ngFor="let tile of otherProcess; let i = index" [class.flipped]="flipped[tile.id]">
            <div [id]="'tile-front-'+i" class="front">
              <div class="head">
                <div class="pic {{ tile.img }}"></div>
                <div class="title">{{ tile.title }}</div>
              </div>
              <div class="read">
                <a class="read-text" (click)="flipTile(tile.id)">Read More</a>
              </div>
            </div>
            <div [id]="'tile-back-'+i" class="back">
              <div class="back-cont">
                <div class="back-title">{{ tile.title }}</div>
                <div class="content">{{ tile.content }}<a rel="noopener noreferrer" target="_blank"
                    href="{{ tile.link }}">{{ tile.linkedText }}</a>.
                </div>
              </div>
              <div class="prev">
                <p class="prev-text" (click)="flipTile(tile.id)"><i class="pic arrow" aria-hidden="true"></i> Back</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="sec-five">
    <div class="sec-container">
      <div class="privacy">
        <p class="title">Privacy at the core: Passionate about privacy, 
          we provide pragmatic advice enabling our business to create value and trust. 
          To know more, click 
          <a rel="noopener noreferrer" target="_blank" class="more-link" href="https://in.accenture.com/legal/ethicscompliance/dataprivacy">here</a>.
        </p>
      </div>
    </div>
  </div>
</section>
