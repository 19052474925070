<div class="p-grid" id="view-wrapper">
  <section class="p-col auto-section" id="view-wrapper-content">
      <app-header class="layout-header" id="view-wrapper-header"></app-header>
      <main id="scrollDistance" style="overflow: auto; position: relative; height: 100%;" cdkScrollable>
          <router-outlet></router-outlet>
      </main>
      <app-footer class="layout-footer" id="view-wrapper-footer"></app-footer>
  </section>
</div>

<app-loading *ngIf="showLoading"></app-loading>
