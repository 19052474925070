import { Component, OnInit, Inject } from '@angular/core';
// import { BroadcastService, MsalService } from '@azure/msal-angular';
// import { Logger, CryptoUtils } from 'msal';

import { MsalBroadcastService, MsalService, MSAL_GUARD_CONFIG, MsalGuardConfiguration, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus, RedirectRequest, SilentRequest } from '@azure/msal-browser';
import { filter } from 'rxjs/operators';

// import { TestUsersProdWhiteList } from 'src/app/common/util/users';
// import { environment } from '../environments/environment';
// import { Router } from '@angular/router';

import * as moment from 'moment';
declare let IM_CLD_CentRum: any;
@Component({
    selector: 'app-root',
    template: `
        <router-outlet></router-outlet>
        <p-toast role="region" aria-label="message" [baseZIndex]="1100" [preventOpenDuplicates]="true" life="10000"></p-toast>
    `,
    styleUrls: []
})
export class AppComponent implements OnInit {

    title = 'msal-angular-tutorial';
    isIframe = false;
    loggedIn = false;

    constructor(
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        @Inject(MSAL_INTERCEPTOR_CONFIG) private SilentRequest: SilentRequest,
        private msalBroadcastService: MsalBroadcastService,
        private authService: MsalService
        // private router: Router
    ) { }

    ngOnInit() {
        this.isIframe = window !== window.parent && !window.opener;

        this.checkoutAccount();

        this.msalBroadcastService.msalSubject$
            .pipe(
                filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
            ).subscribe((result: EventMessage) => {
                this.checkoutAccount();
                this.handleToken(result);
                // graph api setActiveAccount
                let activeAccount = this.authService.instance.getActiveAccount();
                if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
                    let accounts = this.authService.instance.getAllAccounts();
                    this.authService.instance.setActiveAccount(accounts[0]);
                }
            });

        this.msalBroadcastService.msalSubject$
            .pipe(
                filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE),
            ).subscribe((result: EventMessage) => {
            });

        this.msalBroadcastService.msalSubject$
            .pipe(
                filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
            ).subscribe((result: EventMessage) => {
                this.handleToken(result);
            });

        this.msalBroadcastService.msalSubject$
            .pipe(
                filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE),
            ).subscribe((result: EventMessage) => {
            });

        this.msalBroadcastService.inProgress$
            .pipe(
                filter((status: InteractionStatus) => status === InteractionStatus.None)
            )
            .subscribe(() => {
                this.setLoginDisplay();
            })


        // this.msalBroadcastService.subscribe('msal:loginSuccess', (payload) => {
        //     this.checkoutAccount();
        // });

        // this.msalBroadcastService.subscribe('msal:loginFailure', payload => {
        //     // if (window.location.href !== environment.auth.redirectUri) {
        //     //     sessionStorage.setItem('LOGIN_REDIRECT_URL', window.location.href);
        //     // }
        // });

        // this.msalBroadcastService.subscribe('msal:acquireTokenFailure', payload => {
        //     // if renew token failure or cookies expired
        //     this.authService.loginRedirect();
        // });

        // this.msalBroadcastService.subscribe('msal:acquireTokenSuccess', (payload) => {});

        // this.authService.handleRedirectCallback((authError, response) => {
        //     if (authError) {
        //         return;
        //     }
        // });

        // this.authService.setLogger(new Logger((logLevel, message, piiEnabled) => {
        // }, {
        //     correlationId: CryptoUtils.createNewGuid(),
        //     piiLoggingEnabled: false
        // }));

        // this.login();
    }

    setLoginDisplay() {
        this.loggedIn = this.authService.instance.getAllAccounts().length > 0;
    }

    checkoutAccount() {
        // this.loggedIn = !!this.authService.getAccount();
        this.loggedIn = !!this.authService.instance.getAllAccounts()[0];
        // if (environment.isProduction) {
        //     if (this.loggedIn) {
        //         const user = this.authService.getAccount();
        //         const eid = user.idToken.enterpriseID;
        //         if (!(eid.startsWith('T135542.DS') || TestUsersProdWhiteList.indexOf(eid) !== -1)) {
        //             this.router.navigate(['/portal/contantUs']);
        //         }
        //     }
        // }
    }

    login() {
        // if (!this.loggedIn) {
        //     this.authService.loginRedirect({
        //         redirectStartPage: window.location.href
        //     });
        // }
        // console.log(this.authService.instance.getAllAccounts()[0]);
        if (this.msalGuardConfig.authRequest) {
            this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
        } else {
            this.authService.loginRedirect();
        }
    }

    logout() {
        this.authService.logout();
        // this.authService.logoutRedirect({
        //     postLogoutRedirectUri: 'http://localhost:4200'
        //   });
    }

    handleToken(EM: EventMessage) {
        const { eventType, interactionType } = EM;
        const res: any = EM?.payload;
        const accessToken = res.accessToken;
        const accessTokenExpiresOn = res.expiresOn.toString();
        const idTokenClaims = res.idTokenClaims;
        const idToken = res.idToken;
        const idTokenExpiresOn = moment(idTokenClaims.exp * 1000).format('yyyy-MM-DD HH:mm:ss');
        switch (eventType) {
            case EventType.LOGIN_SUCCESS:
                this.setTokenInBrowser(idToken, idTokenExpiresOn, accessToken, accessTokenExpiresOn);
                break;
            case EventType.ACQUIRE_TOKEN_SUCCESS:
                if (interactionType === 'silent') { // whether accesstoken acquired by silence
                    if (!res.fromCache) { // check the token from where? cache or renew
                        this.setTokenInBrowser(idToken, idTokenExpiresOn, accessToken, accessTokenExpiresOn);
                    }
                }
                break;
            default:
                break;
        }
    }

    setTokenInBrowser(idToken: string, idTokenExpiresOn: string, accessToken: string, accessTokenExpiresOn: string) {
        window.sessionStorage.setItem('getToken', 'true');
        window.sessionStorage.setItem('msal.idtoken', idToken);
        window.sessionStorage.setItem('msal.idtoken.expiresOn', idTokenExpiresOn);
        window.sessionStorage.setItem('msal.accessToken', accessToken);
        window.sessionStorage.setItem('msal.accessToken.expiresOn', accessTokenExpiresOn);
    }

}
