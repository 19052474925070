/**
 * this file is used to do some native dom API operations
 */

// -------------------------------------------------- accessibility -------------------------------------------------------------
export const nzBadgeAccessbility = () => {
    setTimeout(() => {
        const read = document.querySelectorAll('.ant-scroll-number-only-unit');
        read.forEach(res => {
            res.setAttribute('aria-hidden', 'true');
            res.setAttribute('tabindex', '-1');
        });
    }, 3000);
}

export const questionCompAccessbility = () => {
    setTimeout(() => {
        const container = document.querySelectorAll('.cdk-visually-hidden');
        if (container) {
            container.forEach((con, j) => {
                con.setAttribute('tabindex', '-1');
            });
        }

        const sidebarIcon = document.querySelectorAll('.ant-menu-submenu-arrow');
        if (sidebarIcon) {
            sidebarIcon.forEach(item => {
                item.setAttribute('tabindex', '0');
            });
        }
    }, 3000);
    setTimeout(() => {
        const read = document.querySelectorAll('.ant-scroll-number-only-unit');
        read.forEach(res => {
            res.setAttribute('aria-hidden', 'true');
            res.setAttribute('tabindex', '-1');
        });
    }, 3000);
    setTimeout(() => {
        const allSelectInOneAct = document.querySelectorAll('textarea');
        const allSelectInAnt = document.querySelectorAll('input');
        if (allSelectInAnt) {
            allSelectInAnt.forEach((select, index) => {
                select.setAttribute('aria-label', 'select box ant-input' + index);
                select.setAttribute('role', '');
            });
        }
        if (allSelectInOneAct) {
            allSelectInOneAct.forEach((select, index) => {
                select.setAttribute('aria-label', 'select box' + index);
            });
        }
        const removeExpand = document.querySelectorAll('input');
        if (removeExpand) {
            removeExpand.forEach(item => {
                item.removeAttribute('aria-expanded');
            });
        }
        const multipleTable = document.querySelectorAll('.p-multiselect-items');
        if (multipleTable) {
            multipleTable.forEach(item => {
                item.setAttribute('role', 'textbox');
                item.setAttribute('aria-label', 'text');
                item.removeAttribute('aria-multiselectable');
            });
        }
        const checkboxInput = document.querySelectorAll('[type=checkbox]');
        if (checkboxInput) {
            checkboxInput.forEach((check, index) => {
                check.setAttribute('aria-label', 'country' + index);
            });
        }
        const roleList = document.querySelectorAll('.ant-collapse-header');
        if (roleList) {
            roleList.forEach(res => {
                res.removeAttribute('role');
            });
        }
        const selectMultiChoice = document.querySelectorAll('.ant-checkbox-wrapper span');
        if (selectMultiChoice) {
            selectMultiChoice.forEach(multi => {
                multi.setAttribute('copy-label', 'true');
            });
        }
        const singleChoice = document.querySelectorAll('.ant-radio-wrapper span');
        if (singleChoice) {
            singleChoice.forEach(single => {
                single.setAttribute('copy-label', 'true');
            });
        }
    }, 3000);
};

export const commonPopupAccessbility = () => {
    setTimeout(() => {
        const container = document.querySelectorAll('.cdk-visually-hidden');
        if (container) {
            container.forEach((con, j) => {
                con.setAttribute('tabindex', '-1');
            });
        }
    }, 2000);
    setTimeout(() => {
        const read = document.querySelectorAll('.ant-scroll-number-only-unit');
        read.forEach(res => {
            res.setAttribute('aria-hidden', 'true');
            res.setAttribute('tabindex', '-1');
        });
    }, 500);
};

export const globalReviewAccessbility = () => {
    const allSelectInOneAct = document.querySelectorAll('textarea');
    const allSelectInAnt = document.querySelectorAll('input');
    const sidebarIcon = document.querySelectorAll('.ant-menu-submenu-arrow');
    if (sidebarIcon) {
        sidebarIcon.forEach(item => {
            item.setAttribute('tabindex', '0');
            item.removeAttribute('aria-hidden');
        });
    }
    if (allSelectInAnt) {
        allSelectInAnt.forEach((select, index) => {
            select.setAttribute('aria-label', 'select box ant-input' + index);
        });
    }
    if (allSelectInOneAct) {
        allSelectInOneAct.forEach((select, index) => {
            select.setAttribute('aria-label', 'select box' + index);
        });
    }
    setTimeout(() => {
        const read = document.querySelectorAll('.ant-scroll-number-only-unit');
        read.forEach(res => {
            res.setAttribute('aria-hidden', 'true');
            res.setAttribute('tabindex', '-1');
        });
        const roleList = document.querySelectorAll('.ant-collapse-header');
        if (roleList) {
            roleList.forEach(res => {
                res.removeAttribute('role');
            });
        }
    }, 1000);
};

export const qnrAccessbility = () => {
    setTimeout(() => {
        const read = document.querySelectorAll('.ant-scroll-number-only-unit');
        read.forEach(res => {
            res.setAttribute('aria-hidden', 'true');
            res.setAttribute('tabindex', '-1');
        });

        const sidebarIcon = document.querySelectorAll('.ant-menu-submenu-arrow');
        if (sidebarIcon) {
            sidebarIcon.forEach(item => {
                item.setAttribute('tabindex', '0');
            });
        }
    }, 1000);
    setTimeout(() => {
        const allSelectInOneAct = document.querySelectorAll('textarea');
        const allSelectInAnt = document.querySelectorAll('input');
        if (allSelectInAnt) {
            allSelectInAnt.forEach((select, index) => {
                select.setAttribute('aria-label', 'select box ant-input' + index);
                select.setAttribute('role', '');
            });
        }
        if (allSelectInOneAct) {
            allSelectInOneAct.forEach((select, index) => {
                select.setAttribute('aria-label', 'select box' + index);
            });
        }
        const inputBtns = document.querySelectorAll('.p-hidden-accessible');
        if (inputBtns) {
            inputBtns.forEach((oneItem, index) => {
                oneItem.firstElementChild.setAttribute('aria-label', 'entities multi select' + index);
                oneItem.firstElementChild.setAttribute('role', '');
            });
        }
        const multiSelect = document.querySelectorAll('[role=listbox]');
        multiSelect.forEach(multi => {
            multi.removeAttribute('role');
        });
    }, 2000);
};

export const removeInputRole = () => {
    const container = document.querySelectorAll('input');
    if (container) {
        container.forEach((con, j) => {
            con.removeAttribute('role');
        });
    }
};

export const removeInputRoleListBox = () => {
    setTimeout(() => {
        const inputRemoveRole = document.querySelectorAll('input');
        if (inputRemoveRole) {
            inputRemoveRole.forEach(item => {
                item.removeAttribute('[role-listbox]');
            });
        }
    }, 1000)
}

export const removePrimengMultiple = () => {
    setTimeout(() => {
        const inputRemoveRole = document.querySelectorAll('input');
        if (inputRemoveRole) {
            inputRemoveRole.forEach(item => {
                item.removeAttribute('[role-listbox]');
            });
        }
        const ulElement = document.querySelectorAll('ul');
        if (ulElement) {
            ulElement.forEach(item => {
                item.setAttribute('role', 'textbox');
                item.setAttribute('aria-label', 'text');
            });
        }
        const removeExpand = document.querySelectorAll('input');
        if (removeExpand) {
            removeExpand.forEach((item) => {
                item.removeAttribute('aria-expanded');
            });
        }
        const removeMultiSelectable = document.querySelectorAll('ul');
        if (removeMultiSelectable) {
            removeMultiSelectable.forEach((item) => {
                item.removeAttribute('aria-multiselectable');
            });
        }
    }, 1000);
}

export const qnrClickSectionAccessbility = () => {
    setTimeout(() => {
        const removeRole = document.querySelectorAll('[role-listbox]');
        if (removeRole) {
            removeRole.forEach(item => {
                item.removeAttribute('role');
            });
        }
        const removeExpand = document.querySelectorAll('input');
        if (removeExpand) {
            removeExpand.forEach(item => {
                item.removeAttribute('aria-expanded');
            });
        }
        const multipleTable = document.querySelectorAll('.p-multiselect-items');
        if (multipleTable) {
            multipleTable.forEach(item => {
                item.removeAttribute('role');
                item.removeAttribute('aria-multiselectable');
            });
        }
        const ulElement = document.querySelectorAll('ul');
        if (ulElement) {
            ulElement.forEach((item, index) => {
                if (index === 0) {
                    item.setAttribute('role', 'textbox');
                    item.setAttribute('aria-label', 'text');
                }
            });
        }
    }, 1000);
};

export const localReviewAccessbility = () => {
    setTimeout(() => {
        const closeBtn: any = document.querySelectorAll('.p-toast-icon-close');
        closeBtn.forEach((res) => {
            res.setAttribute('aria-label', 'Close');
        });
        // const role = document.querySelectorAll('.ant-collapse-header');
        // role.forEach(res => {
        //     res.removeAttribute('role')
        // });
        const allSelectInOneAct = document.querySelectorAll('textarea');
        const allSelectInAnt = document.querySelectorAll('input');
        if (allSelectInAnt) {
            allSelectInAnt.forEach((select, index) => {
                select.setAttribute('aria-label', 'select box ant-input' + index);
                select.removeAttribute('role');
            });
        }
        if (allSelectInOneAct) {
            allSelectInOneAct.forEach((select, index) => {
                select.setAttribute('aria-label', 'select box' + index);
                select.style.overflow = 'visible';
            });
        }
        const read = document.querySelectorAll('.ant-scroll-number-only-unit');
        read.forEach(res => {
            res.setAttribute('aria-hidden', 'true');
            res.setAttribute('tabindex', '-1');
        });
    }, 2000);
};

export const localReviewToastAccessbility = () => {
    setTimeout(() => {
        const container = document.querySelectorAll('.p-toast-icon-close');
        if (container) {
            container.forEach((con, j) => {
                con.setAttribute('aria-label', 'close');
            });
        }
    }, 1000);
};

export const localTeamAccessbility = () => {
    setTimeout(() => {
        const toasteBtn = document.querySelectorAll('.p-toast-icon-close');
        if (toasteBtn) {
            toasteBtn.forEach(tosBtn => {
                tosBtn.setAttribute('aria-label', 'close');
            });
        }
        const removeExpand = document.querySelectorAll('input');
        if (removeExpand) {
            removeExpand.forEach((item) => {
                item.removeAttribute('aria-expanded');
            });
        }
        const haspopup = document.querySelectorAll('[aria-haspopup=listbox]');
        haspopup.forEach(popup => {
            popup.removeAttribute('role');
        });
        richTextAccessbility();
        const role = document.querySelectorAll('.ant-radio-input');
        role.forEach(res => {
            res.setAttribute('aria-labelledby', 'action-detail-alteration-question');
        });
        const multiSelect = document.querySelectorAll('[role=listbox]');
        multiSelect.forEach(multi => {
            multi.removeAttribute('role');
            multi.setAttribute('aria-hidden', 'true');
            multi.setAttribute('tab-index', '-1');
        });
    }, 3000);
};

export const ToastAccessbility = () => {
    setTimeout(() => {
        const container = document.querySelectorAll('.p-toast-icon-close');
        if (container) {
            container.forEach((con, j) => {
                con.setAttribute('aria-label', 'close');
            });
        }
    }, 1000);
};

export const ManageDocumentAccessbility = () => {
    setTimeout(() => {
        const container = document.querySelectorAll('.cdk-visually-hidden');
        if (container) {
            container.forEach((con, j) => {
                con.setAttribute('tabindex', '-1');
            });
        }
    }, 1000);
};

export const multiAccessbitity = () => {
    // multiple select
    setTimeout(() => {
        const removeRole = document.querySelectorAll('[role=listbox]');
        if (removeRole) {
            removeRole.forEach(item => {
                item.removeAttribute('role');
            });
        }
        const removeExpand = document.querySelectorAll('input');
        if (removeExpand) {
            removeExpand.forEach(item => {
                item.removeAttribute('aria-expanded');
            });
        }
    }, 1000);
};

export const multiOpenAccessbility = () => {
    setTimeout(() => {
        const removeExpand = document.querySelectorAll('input');
        if (removeExpand) {
            removeExpand.forEach(item => {
                item.removeAttribute('aria-expanded');
            });
        }
        const multipleTable = document.querySelectorAll('.p-multiselect-items');
        if (multipleTable) {
            multipleTable.forEach(item => {
                item.removeAttribute('aria-multiselectable');
                item.setAttribute('role', 'textbox');
                item.setAttribute('aria-label', 'text');
            });
        }
    }, 2000);
};

export const legalAssAccessbility = () =>  {
    setTimeout(() => {
        const description = document.querySelectorAll('.ant-select-selection-search-input');
        const role = document.querySelectorAll('.ant-collapse-header');
        description.forEach((res, index) => {
            res.setAttribute('aria-label', 'select' + index);
        });
        role.forEach(roleRes => {
            roleRes.removeAttribute('role')
        });
        richTextAccessbility();
    }, 3000);
};

export const globalLegalReviewAccessibility = () => {
    setTimeout(() => {
        const allSelectInOneAct = document.querySelectorAll('textarea');
        if (allSelectInOneAct) {
            allSelectInOneAct.forEach((select, index) => {
                select.setAttribute('aria-label', 'select box' + index);
                // this.render.setStyle(select, 'overflow', 'visible')
            });
        }
        const role = document.querySelectorAll('.ant-collapse-header');
        role.forEach(res => {
            res.removeAttribute('role');;
        });
        const read = document.querySelectorAll('.ant-scroll-number-only-unit');
        read.forEach(res => {
            res.setAttribute('aria-hidden', 'true');
            res.setAttribute('tabindex', '-1');
        });
        const toasteBtn = document.querySelectorAll('.p-toast-icon-close');
        if (toasteBtn) {
            toasteBtn.forEach(tosBtn => {
                tosBtn.setAttribute('aria-label', 'close');
            });
        }
        richTextAccessbility();
    }, 5000);
};

const richTextAccessbility = () => {
    const richTextSpan = document.getElementsByTagName('iframe');
    // tslint:disable-next-line:prefer-for-of
    for (let index = 0; index < richTextSpan.length; index++) {
        const element = richTextSpan[index].contentDocument;
        const bodies = element.getElementsByTagName('body');
        const spans = element.getElementsByTagName('span');
        const ps = element.getElementsByTagName('p');
        // tslint:disable-next-line:prefer-for-of
        for (let index = 0; index < bodies.length; index++) {
            const body = bodies[index];
            body.removeAttribute('aria-label');
        }
        // tslint:disable-next-line:prefer-for-of
        for (let index = 0; index < spans.length; index++) {
            const span = spans[index];
            span.setAttribute('aria-label', `'text' + ${index}`);
            span.setAttribute('role', 'region');
        }
        for (let index = 0; index < ps.length; index++) {
            const p = ps[index];
            p.setAttribute('aria-label', `'content' + ${index}`);
            p.setAttribute('role', 'region');
        }
    }
};
// -------------------------------------------------- accessibility end ---------------------------------------------------------
