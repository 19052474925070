<div class="header-container" id="header-container">
    <div class="header-left-part" id="header-container-left-part">
        <img src="./assets/images/accenturelogo.svg" class="acc-logo" alt="accenture-logo" />
        <img src="./assets/images/header_line.png" alt="accenture-logo" />
        <span class="header-label" role="region" aria-label="glr">Global Legal Review (GLR)</span>
    </div>
    <div class="header-right-part" id="header-container-right-part">

        <div class="header-right-link">
            <div *ngFor="let item of headerList" class="header-item" [class.active]="item.active">
                <ng-container [ngSwitch]="item.name">
                    <div *ngSwitchCase="'Home'" [id]="item.id" (click)="changeMenu(item.name)">
                        <span role="region" aria-label="home" >{{ item.name }}</span>
                    </div>
                    <div *ngSwitchCase="'My GLR'" [id]="item.id" (click)="changeMenu(item.name)">
                        <span role="region" aria-label="myglr" >{{ item.name }}</span>
                    </div>
                    <a *ngSwitchCase="'Resources'" rel="noopener noreferrer" [id]="item.id"
                        href="https://in.accenture.com/legal/ethicscompliance/dataprivacy/globallegalreview"
                        target="_blank" aria-label="Resources">
                        <span role="region"  aria-label="Resources" >{{ item.name }}</span>
                    </a>
                </ng-container>
            </div>
        </div>
        <div style="display: none;" #bellPopover></div>
        <div class="admin-settings" (click)="openManageUser()" *ngIf="isAdmin">
            <img src="./assets/images/admin_settings_inactive.svg" *ngIf="!openManagePage" class="clear-icon"
            alt="clear-icon" />
            <img src="./assets/images/admin_settings.svg" *ngIf="openManagePage" class="clear-icon"
            alt="clear-icon" />
        </div>
        <div class="header-right-icon header-app" nz-popover
            [nzPopoverContent]="contentTemplate" nzPopoverPlacement="bottomRight"
            [nzPopoverVisible]="popoverVisible && initPopoverVisible"
            (nzPopoverVisibleChange)="popoverVisibleChange($event)"
            [nzPopoverTrigger]="'click'" nzType="primary">
            <i class="fa fa-bell hd-icon-bell" *ngIf="discussionUnread === 0"></i>
            <nz-badge [nzCount]="discussionUnread" [class.more-than]="discussionUnread>99"  [nzOverflowCount]="99" role="region" aria-label="notification" [nzTitle]="null" *ngIf="discussionUnread !== 0" class="bell-badge" >
                <i class="fa fa-bell hd-icon-bell"></i>
            </nz-badge>
        </div>

        <ng-template #contentTemplate>
            <ul *ngIf="bellMsgList.length>0" class="bell-list" role="region" aria-label="message">
                <li *ngFor="let item of bellMsgList" role="region" aria-label="messageItem">
                    <div class="item-title">
                        <span>{{item.notificationDate}}</span>
                        <img src="./assets/images/delete_bell_msg.svg" (click)="removeItem(item)" class="clear-icon" alt="clear-icon" />
                    </div>
                    <div class="bell-mesg-content" *ngIf="item.messageType==='ReopenVersion'; else bellReopenTemp">
                        <!-- You have a new revision request for case  -->
                        You have a request to review a revised scope for case
                        <span (click)="goToCaseDetail(item)">Case {{item.caseNbr}}</span>.
                        <!-- Please review and complete the request -->
                        Please review and complete the relevant actions
                    </div>
                    <ng-template #bellReopenTemp>
                        <div class="bell-mesg-content">
                            You have new discussion/reply awaiting your review for the case
                            <span (click)="goToCaseDetail(item)">Case {{item.caseNbr}}</span>
                        </div>
                    </ng-template>
                </li>
            </ul>
            <div *ngIf="bellMsgList.length==0">You do not have any notifications yet!</div>
        </ng-template>
        <!-- <div class="header-right-icon header-help">
      <img src="./assets/images/icon-apps.svg" class="img-chevron" alt="chevron-right-icon" />
    </div> -->
        <div class="header-right-icon" id="header-container-right-part-logout">
            <img src="./assets/images/error_people.svg" *ngIf="!apiError" class="user-icon" alt="chevron-right-icon" (click)="clickImg()"
                nz-popover nzType="primary" nzPopoverPlacement="bottomRight" [nzPopoverContent]="contentTemplateUser" nzPopoverOverlayClassName="user_pop"/>
            <img placement="bottom" class="user-icon" *ngIf="apiError" (click)="clickImg()" src="{{userPictureUrl}}" alt="picture"
                nz-popover nzType="primary" nzPopoverPlacement="bottomRight" [nzPopoverContent]="contentTemplateUser" nzPopoverOverlayClassName="user_pop" />
        </div>

        <ng-template #contentTemplateUser>
            <ul class="login-info">
                <li>{{ user.idTokenClaims['enterpriseID'] }}</li>
                <li *ngIf="showLogOut" class="logout" (click)="logout()"><i class="fa fa-sign-out" style="margin-right: 6px;"></i>sign out</li>
            </ul>
        </ng-template>
    </div>
</div>
