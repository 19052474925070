import { Component, ViewChild, TemplateRef, OnInit } from '@angular/core';
import { ACModalService } from 'src/app/services/utils/modal.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  @ViewChild('termsOfUse') termsOfUse: TemplateRef<any>;

  constructor(
    private acModal: ACModalService
    ) { }

  ngOnInit() {
  }

  openPrivacy() {
    window.open('https://in.accenture.com/protectingaccenture/data-security/5422-2/');
  }

  openTerms() {
    this.acModal.openModaltplTitle(null, this.termsOfUse, null, 840, 'terms-modal');
  }

  closeModal() {
    this.acModal.closeModal();
  }

}
