import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RequestService } from '../http/request.service';
import { GeneralResponse } from 'src/app/interfaces/GeneralResponse';

@Injectable({
    providedIn: 'root'
})
export class MyglrService extends RequestService {

    showCreate: any = null;

    constructor(http: HttpClient) {
        super(http);
    }

    public GetCreatePermission(): Promise<GeneralResponse> {
        return this.get('/api/Home/CreatePermission');
    }

    // myGLR cardview
    public GetCardlist(cardListModel?): Promise<GeneralResponse> {
        return this.post('/api/MyGLR/GetCardList', cardListModel);
    }

    public GetMyGLRConfig(): Promise<GeneralResponse> {
        return this.get('/api/MyGLR/GetConfig');
    }

    public GetMyGLRShowMeAgain(): Promise<GeneralResponse> {
        return this.get('/api/MyGLR/GetShowMeAgain');
    }

    public setMyGLRShowMeAgain(isSelected): Promise<GeneralResponse> {
        return this.post('/api/MyGLR/OpShowMeAgain', isSelected);
    }

    public GetCaseInfo(obj): Promise<GeneralResponse> {
        return this.post('/api/CaseSummary/GetCaseInfo', obj);
    }

    // get reopened case id
    public GetReopenCaseId(caseId): Promise<GeneralResponse> {
        return this.get('/api/Reopen/ReopenCaseUrlReturn', caseId);
    }

    // delete deadline case version
    public deleteDeadlineCaseVersion(caseId: number): Promise<GeneralResponse> {
      return this.post('/api/Reopen/DeleteCaseReopenDeadline', { caseId });
  }

    // need delete ------------------------------------------
    public fixUserData(): Promise<GeneralResponse> {
        return this.get('/api/MyGLR/userfixed');
    }
    // ........................................................

    // ------------------------ legal Assessment -------------------------------------
    public setCaseReadyStatus(obj): Promise<GeneralResponse> {
        return this.post('/api/LegalAssessment/SetCaseReadyStatus', obj);
    }

    public getAssessmentInfo(obj): Promise<GeneralResponse> {
        return this.post('/api/LegalAssessment/GetLegalAssessment', obj);
    }

    public caseSummaryAutoSave(obj): Promise<GeneralResponse> {
        return this.post('/api/LegalAssessment/CaseSummaryAutoSave', obj);
    }

    public conclusionAutoSave(obj): Promise<GeneralResponse> {
        return this.post('/api/LegalAssessment/AssessmentConclusionAutoSave', obj);
    }

    public KeyScopeUpdateAutoSave(obj): Promise<GeneralResponse> {
        return this.post('/api/LegalAssessment/KeyScopeUpdateAutoSave', obj);
    }

    public saveAction(obj): Promise<GeneralResponse> {
        return this.post('/api/LegalAssessment/SaveAction', obj);
    }

    public deleteAction(obj): Promise<GeneralResponse> {
        return this.post('/api/LegalAssessment/DeleteAction', obj);
    }

    public autoSaveRelevant(obj): Promise<GeneralResponse> {
        return this.post('/api/LegalAssessment/AssessmentTextAutoSave', obj);
    }

    public saveCheckbox(obj): Promise<GeneralResponse> {
        return this.post('/api/LegalAssessment/SetPrivacyDisplay', obj);
    }

    public getActionHistory(obj): Promise<GeneralResponse> {
        return this.post('/api/LegalAssessment/GetActionHistory', obj);
    }
    public reopenErbAction(obj): Promise<GeneralResponse> {
        return this.post('/api/LegalAssessment/ReopenERBAction', obj);
    }

    // ---------------------------------------------------------------------------
    public getMyGlrCaseId(): Promise<GeneralResponse> {
        return this.get('/api/MYGLR/GetCaseNumber');
    }

    public autoSaveGlobalRelevant(obj): Promise<GeneralResponse> {
        return this.post('/api/GlobalReview/RelevantFactAutoSave', obj);
    }

    public globalLegalAssessmentHistory(obj): Promise<GeneralResponse> {
        return this.get('/api/GlobalReview/GetLegalHistory', obj);
    }

    public globalAssessmentHistory(obj): Promise<GeneralResponse> {
        return this.get('/api/GlobalReview/GetGlobalLegalHistory', obj);
    }

    // ------------------------ upLoad to Azure -------------------------------------
    public uploadToAzureSotrage(obj): Promise<any> {
        return this.postfile('/api/Attachment/PostAttachmenttoStorage', obj);
    }

    public uploadToAzure(obj): Promise<GeneralResponse> {
        return this.post('/api/Attachment/UploadAttachmentAzure', obj);
    }

    public deleteFileFromAzure(obj): Promise<GeneralResponse> {
        return this.post('/api/Attachment/DeleteAttachmentAzure', obj);
    }

    public setStateAzure(obj): Promise<GeneralResponse> {
        return this.post('/api/Attachment/SetUploadStatus', obj);
    }

    public upDateDiscribe(obj): Promise<GeneralResponse>{
        return this.post('/api/Attachment/UpdateDescription', obj);
    }

    // ------------------------ email notification  -------------------------------------

    public getHeaderBellMsg(): Promise<GeneralResponse> {
        return this.get('/api/Home/GetUserNotifications');
    }

    public deleteNotificationMsgItem(obj): Promise<GeneralResponse>{
        return this.post('/api/Home/DeleteNotifications', obj);
    }

    public getCaseNotificaitonSeletGroup(caseId): Promise<GeneralResponse> {
        return this.get('/api/Discussion/GetCaseNotificaitonSeletRoleType',{caseId});
    }

    public getCaseNotificaitonSeletUser(params): Promise<GeneralResponse> {
        return this.post('/api/Discussion/GetCaseNotificaitonSeletGroup',params);
    }

    public sendDiscussionNotification(params): Promise<GeneralResponse> {
        return this.post('/api/Discussion/SendDiscussionNotification',params);
    }

    public GetAllCountries(): Promise<GeneralResponse> {
        return this.get('/api/MyGLR/GetOrganization');
    }

    public getOrganizationUser(params): Promise<GeneralResponse> {
        return this.post('/api/MyGLR/GetOrganizationUser',params);
    }

    public changeLocalUser(params): Promise<GeneralResponse> {
        return this.post('/api/MyGLR/ChangeLocalUser',params);
    }

    public doshboard(): Promise<GeneralResponse> {
        return this.get('/api/MyGLR/ExportDoshboard');
    }

    public getEmailMessage(caseId: number): Promise<GeneralResponse> {
        return this.get('/api/Discussion/GetDiscussionNotification', { caseId });
    }

    // Discussion total unread on left menu - Discussion
    public getTotalUnreadCount(caseId: number) {
        return this.post('/api/MyGLR/GetDiscussionSectionUnRead', { caseId });
    }

}
