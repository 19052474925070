import { Component, OnInit } from '@angular/core';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
    selector: 'app-view-layout',
    templateUrl: './view-layout.component.html',
    styleUrls: ['./view-layout.component.scss']
})
export class ViewLayoutComponent implements OnInit {

    showLoading = true;

    constructor(private loading: LoadingService) {
        this.loading.oncommitLoading().subscribe((loading) => {
            setTimeout(() => {
                this.showLoading = loading;
            });
        });
    }

    ngOnInit() {}

}
