import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';

import { Observable, pipe, of } from 'rxjs';
import { catchError, tap, timeout } from 'rxjs/operators';

import { MessageService } from 'primeng/api';

import * as _ from 'lodash';

@Injectable()
export class BaseHttpInterceptor implements HttpInterceptor {

    private noTimeoutApiList: Array<string>;

    constructor(private msgs: MessageService) {
        this.noTimeoutApiList = [
            '/api/Attachment/UploadAttachmentAzure',
            '/api/Attachment/DownloadAttachmentAzure',
            '/api/MyGLR/GetCardList',
            '/api/MyGLR/ExportDoshboard',
            '/api/Reopen/MarkReopenComplete',
            '/api/Reopen/ReopenQuestionnaire'
        ];
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const self = this;
        // Block on request， add headers
        const requestInterceptor = this.dealRequest(req);
        const isUploadApi = this.noTimeoutApiList.some(e => req.url.includes(e));
        let apiTimeout = isUploadApi ? 6000000 : 30000;
        if (!environment.production) {
            apiTimeout = 6000000;
        }
        return next.handle(requestInterceptor).pipe(
            timeout(apiTimeout), // Time out
            catchError((err: HttpErrorResponse) => self.handleErrResponse(err))
        );
    }

    protected dealRequest(req: HttpRequest<any>): HttpRequest<any> {
        let headers = new HttpHeaders();
        headers
            .set('Cache-Control', 'no-cache')
            .set('Accept', 'application/jsons')
            .set('Accept', 'application/octet-stream');//application/jsons
            // .set('Pragma', 'no-caches')
            // .set('Cache-Control', 'no-store');
        // if (environment.production) {
        // headers = this.setAuthorization(headers);
        // }
        // headers = this.addAjaxHeaders(headers);
        return req.clone({ headers });
    }

    protected setAuthorization(headers: HttpHeaders): HttpHeaders {
        let authorization = headers ? headers.get('Authorization') : null;
        if (!authorization) {
            authorization = '';
        }
        if (!_.startsWith(authorization, 'Bearer')) {
            const token = sessionStorage.getItem('msal.idtoken');

            if (token && headers) {
                headers = headers.set('Authorization', 'Bearer ' + token);
            }
        }
        return headers;
    }

    protected addAjaxHeaders(headers: HttpHeaders): HttpHeaders {
        if (headers) {
            headers = headers.set('X-Requested-With', 'XMLHttpRequest');
        }
        return headers;
    }

    protected handleErrResponse(err: any): Observable<any> {
        let msg = '';
        switch  (err.status) {
            case 400:
                msg = 'Bad request';
                break;
            case 401:
                msg = 'Not authorized';
                break;
            case 403:
                msg = 'Forbidden';
                break;
            case 404:
                msg = 'Resource not found';
                break;
            case 405:
                msg = 'Method not allowed';
                break;
            case 406:
                msg = 'Accept type is invalid';
                break;
            case 500:
                msg = 'Timeout or server error';
                break;
            case 502:
                msg = 'gateway error';
                break;
            default:
                msg = 'Timeout or server error';
        }
        // this.msgs.add({ severity: 'error', detail: msg});
        return of(err);
    }

}
