import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { delay, share } from 'rxjs/operators';
@Injectable({
    providedIn: 'root'
})
export class LoadingService {

    public loading$ = new BehaviorSubject(false);
    constructor() {}

    commitLoading(val) {
        this.loading$.next(val);
    }

    oncommitLoading(): Observable<any> {
        return this.loading$.pipe(
            share()
        );
    }

}
