import { Component, OnChanges, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingService } from 'src/app/services/loading.service';
import { MyglrService } from '../../../../../app/services/api/myglr.service';

@Component({
    selector: 'app-create-case-modal',
    templateUrl: './create-case-modal.component.html',
    styleUrls: ['./create-case-modal.component.scss']
})
export class CreateCaseModalComponent implements OnInit {

    @Input() isDailyShow: boolean;
    @Output() backInfo = new EventEmitter<any>();

    caseId;

    constructor(private router: Router, private myGlrService: MyglrService, private loading: LoadingService) {}

    ngOnInit(): void {}

    handleCancel() {
        this.backInfo.emit({ isShow: false });
    }

    handleOk() {
        this.backInfo.emit({ isShow: false });
        this.getCaseId();
    }

    getCaseId() {
        this.loading.commitLoading(true);
        this.myGlrService.getMyGlrCaseId().then(res => {
            this.router.navigate(['/portal/myGLR/question'], {
                queryParams: {
                    caseId: res.response.caseId
                }
            });
        }).finally(() => {
            this.loading.commitLoading(false);
        });
    }

}
