<nz-modal [nzVisible]="isDailyShow" (nzOnCancel)="handleCancel()" nzWidth="840px" [nzContent]="modalContent" [nzFooter]="null" [nzAutofocus]="null" [nzCloseIcon]="closeIconTemplate">
    <ng-template #modalContent>
        <div class="modal_container">
            <div class="modal_body">You are about to create a GLR case. A unique Case ID will be generated for this.
                Are you sure you want to continue?</div>
            <div class="modal_footer fr">
                <button class="no-btn mr16" (click)="handleCancel()">Cancel</button>
                <button class="ant-btn ok-btn" (click)="handleOk()">Continue</button>
            </div>
        </div>
    </ng-template>
    <ng-template #closeIconTemplate>
        <i id="icon_close" class="popup-icon-close" aria-hidden="true" tabindex="-1" (click)="handleCancel()"></i>
    </ng-template>
</nz-modal>
