import { Injectable } from '@angular/core';
import { RoleCode, GroupCode } from '../../views/modules/myGLR/model/enum/index';

import { StatusBarModel } from 'src/app/interfaces/statusbar';

import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  public changeDownloadLabel = 'N';
  public closeCaseBtnDisabled: boolean;
  public caseId: number;

  public caseInfo: any;

  public caseDiscussInfo = {
    discussionDisplay: false,
    discussionHasActived: false,
    unRead: 0
  };

  private authList;
  public manualCloseStatus;
  public isAdminUser;
  // public hasTriggerDP;

  get role() {
    return this.authList || [];
  }

  set role(data) {
    this.authList = data;
  }

  private __statusBarData: StatusBarModel;

  public get statusBarData(): StatusBarModel {
    return this.__statusBarData;
  }

  public set statusBarData(data) {
    this.__statusBarData = data;
  }

  public get isFinalStatus() {
    try {
      const {
        completed,
        legalReview,
        legalReviewDTO: {
          globalLegalReview,
          localReview,
          finalReview
        }
      } = this.statusBarData;
      if (
        !completed &&
        legalReview &&
        (globalLegalReview === 'Completed' || globalLegalReview === 'Skipped') &&
        localReview === 'Completed' &&
        finalReview === 'Process'
      ) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  constructor() {
    this.initCaseInfo();
  }

  initCaseInfo() {
    this.caseInfo = {
      caseName: '',
      caseNbr: '',
      caseStatus: '',
      closeStatus: false,
      caseStatusBranch: 'N',
      isManualClose: false,
      isDownloadDisplay: false,
      isReOpen: false,
      versionList: [],
      lacoption: '',
      lacoptionComment: '',
      LacFullCloseRadio: '',
      reopenButtonDisplayFlag: 'N',
      submitInd: false,
      oldVersionStatus: '',
      isOnlyHasImpact: '',
      hasTriggerDP: false,
      isReopened: 'N',
      inUseInd: true,
      isOldVersionCase: false,
      statusBeforeMC: '',
      isELROrWC: false,
      questionnaireVersion: '',
      reopenToast: '',
      reopenMarkInd: false,
      previousScope: {},
      hasChangeCountry: false,
      hasChangeScope: false,
      hasChangeAFS: false,
      hasChangeACN: false,
      hasChangeAVA: false,
      readOnly: false,
      isShowRisk: false,
      statusCurrentMC: '',
      isUpdateScoreLabel: false,
      selectedScenario: [],
      disableCloseBtn: true,
      globalReviewSkipped: false,
      showGlobalPopup: true,
      caseTypeCd: '',
      isCaseClosed: false
    };
  }

  // global team
  public isOnlyDP() { // Only single role: DP
    return _.some(this.authList, { roleCode: RoleCode.GR, caseRoleGroupDTOs: [{ groupCode: GroupCode['GLR-GDP'] }] }) && this.authList.length == 1;
  }

  public isDP() { // Also useful which user has double permission DP & Admin
    return _.some(this.authList, { roleCode: RoleCode.GR, caseRoleGroupDTOs: [{ groupCode: GroupCode['GLR-GDP'] }] });
  }

  public isAssignDP() {
    return _.some(this.authList, { assign: true, roleCode: RoleCode.GR, caseRoleGroupDTOs: [{ groupCode: GroupCode['GLR-GDP'] }] });
  }

  public isNotAssignDP() {
    return _.some(this.authList, { assign: false, roleCode: RoleCode.GR, caseRoleGroupDTOs: [{ groupCode: GroupCode['GLR-GDP'] }] });
  }

  public isGEL() {
    return _.some(this.authList, { roleCode: RoleCode.GR, caseRoleGroupDTOs: [{ groupCode: GroupCode['GLR-GEL'] }] });
  }

  public isED() {
    return _.some(this.authList, { roleCode: RoleCode.GR, caseRoleGroupDTOs: [{ groupCode: GroupCode['GLR-ED'] }] });
  }

  public isTC() {
    return _.some(this.authList, { roleCode: RoleCode.GR, caseRoleGroupDTOs: [{ groupCode: GroupCode['GLR-TC'] }] });
  }

  public isIPT() {
    return _.some(this.authList, { roleCode: RoleCode.GR, caseRoleGroupDTOs: [{ groupCode: GroupCode['GLR-IPT'] }] });
  }

  public isCI() {
    return _.some(this.authList, { roleCode: RoleCode.GR, caseRoleGroupDTOs: [{ groupCode: GroupCode['GLR-CI'] }] });
  }

  public isDPO() {
    return _.some(this.authList, { roleCode: RoleCode.GR, caseRoleGroupDTOs: [{ groupCode: GroupCode['GLR-DPO'] }] });
  }

  public isFYI() {
    return _.some(this.authList, { roleCode: RoleCode.GR, caseRoleGroupDTOs: [{ groupCode: GroupCode['GLR-OTHER'] }] });
  }

  public isGCC() {
    return _.some(this.authList, { roleCode: RoleCode.GR, caseRoleGroupDTOs: [{ groupCode: GroupCode['GLR-GCC'] }] });
  }

  public isGR(): boolean {
    return _.some(this.authList, { roleCode: RoleCode.GR });
  }

  public getGroupName(): string {
    const group = _.find(this.authList, { roleCode: RoleCode.GR });
    return group.caseRoleGroupDTOs[0].groupName;
  }

  public isOther() {
    return _.some(this.authList, { roleCode: RoleCode.GR, caseRoleGroupDTOs: [{ groupCode: GroupCode['GLR-GDP'] }] });
  }

  public isAssignedLacorAdmin() {
    return this.authList?.filter(item => {
      return item.roleCode === RoleCode.Admin || (item.roleCode === RoleCode.LAC && item.assign);
    }).length > 0;
  }

  public isLacButNotAssigned() {
    return this.authList.filter(item => {
      return item.roleCode === RoleCode.LAC && !item.assign;
    }).length > 0;
  }

  public isPM() {
    return _.some(this.authList, { roleCode: RoleCode.PM });
  }

  public isPMorCFL() {
    return _.some(this.authList, { roleCode: RoleCode.PM }) || _.some(this.authList, { roleCode: RoleCode.CFL });
  }

  public isAdmin() {
    return _.some(this.authList, { roleCode: RoleCode.Admin });
  }

  public isCFL() {
    return _.some(this.authList, { roleCode: RoleCode.CFL });
  }

  public isCFL_and_MD() {
    return _.some(this.authList, { roleCode: RoleCode.PM, groupCode: 'PM-MD' }) && _.some(this.authList, { roleCode: RoleCode.CFL });
  }

  public isCFL_NOT_MD() {
    return (!_.some(this.authList, { roleCode: RoleCode.PM, groupCode: 'PM-MD' })) && _.some(this.authList, { roleCode: RoleCode.CFL });
  }

  public isMD() {
    return _.some(this.authList, { roleCode: RoleCode.PM, groupCode: 'PM-MD' });
  }

  public isAssignLAC() {
    return this.authList?.filter(item => {
      return item.roleCode === RoleCode.LAC && item.assign;
    }).length > 0;
  }

  // -------------------- local ---------------------------------------
  public isPMandLOR() { }

  // Multi-roles: Add 'assign' value for checking depending on the current case's review type

  public isLOR() { // except ELR & WC
    return _.some(this.authList, e => e.roleCode === RoleCode.LOR && (e.roleType !== RoleCode.ELR && e.roleType !== RoleCode.WC) && e.assign == true);
  }

  public isELR() {
    return _.some(this.authList, e => e.roleCode === RoleCode.LOR && e.roleType === RoleCode.ELR && e.assign == true);
  }

  public isERB() {
    return _.some(this.authList, e => e.roleCode === RoleCode.LOR && e.roleType === RoleCode.WC && e.assign == true);
  }

  public isELR_Or_WC() {
    return _.some(this.authList, e => e.roleCode === RoleCode.LOR && (e.roleType === RoleCode.ELR || e.roleType === RoleCode.WC) && e.assign == true);
  }

  public getLocalCountry(): string {
    const group = _.find(this.authList, { roleCode: RoleCode.LOR });
    return group.caseRoleGroupDTOs[0].groupName;
  }
  // -------------------- local end ---------------------------------------
  public isLR() {
    return _.some(this.authList, { roleCode: RoleCode.PM, caseRoleGroupDTOs: [{ groupCode: 'PM-LR' }] });
  }

  public isSR() {
    return _.some(this.authList, { groupCode: 'PM-SR' });
  }
}
