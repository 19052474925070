import { Injectable } from '@angular/core';

@Injectable()
export class LoggerService {

  log(msg: string): void {
  }

  error(msg: string): void {
    console.error(msg);
  }

}
